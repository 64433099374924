import React from "react"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import usecaseImg from "../images/usercase.svg"

export default () => {
  return (
    <>
      <Breadcrumbs
        title="Caso de uso"
        description="No tener una herramienta ETL es un problema pero tenerla y que procese un 4% de sus datos con errores podría ser catastrofico"
      />
      <div className="container-fluid usercase">
        <div className="container">
          <div className="row">
            <div
              className="col-md-4 col-sm-12"
              data-sal="slide-up"
              data-sal-delay="200"
            >
              <h5 className="text-primary mb-5">
                La dirección de negocio de un grupo empresarial requiere un
                sistema de información que les proporcione informacion de
                ventas, marketing,etc
              </h5>
              <h5 className="text-primary mb-5">
                La mayoría de empresas del grupo tienen su ERP con Oracle y el
                resto con SAP sobre SQL Server.
              </h5>
              <h5 className="text-secondary mb-4">
                <strong>
                  El departamente de BI tiene un gran reto para integrar todas
                  esas fuentes de datos en su Data Warehouse y eligen Open
                  Datastage como plataforma para las gestión de su DWH.
                </strong>
              </h5>
              <img src={usecaseImg} width="80%" />
            </div>
            <div
              className="col-md-8 col-sm-12"
              data-sal="slide-up"
              data-sal-delay="400"
            >
              <h2 className="mb-3 mt-4 mt-md-0">¿Qué han logrado hacer?</h2>
              <h5 className="font-weight-normal">
                <i className="las la-check text-secondary"></i> Automatizar la
                extracción de 70 bases de datos, usando templates SQL
                parametrizables para no repetir las consultas en cada una de las
                bases de datos que tienen el mismo sistema ERP
              </h5>

              <h5 className="font-weight-normal">
                <i className="las la-check text-secondary"></i> Automatizar la
                carga de ficheros de datos de usuarios
              </h5>

              <h5 className="font-weight-normal">
                <i className="las la-check text-secondary"></i> Con un equipo de
                2 personas no técnicas y con conocimientos de sql mantener un
                dwh con el minimo esfuerzo y centrarse en el desarrollo de
                cuadros de mando y en las generacion de datos analiticos para un
                grupo empresarial de mas 1000 empleados.
              </h5>

              <h5 className="font-weight-normal">
                <i className="las la-check text-secondary"></i> Tener un DWH sin
                necesidad de tener expertos en ETLs, con analistas centrados en
                desarrollar sus cuadros de mandos y en generar datos analíticos
                importantes para el negocio.
              </h5>

              <h5 className="font-weight-normal">
                <i className="las la-check text-secondary"></i> Tener un equipo
                de 2 analistas centrados en desarrollar cuadros de mandos y
                datos analíticos.
              </h5>

              <h5 className="font-weight-normal">
                <i className="las la-check text-secondary"></i> Definir una
                estructura jerarquica de procesos para definir los procesos
                Batch y las dependencias entre ellos. Por ejemplo, se han
                definido 4 steps sequenciales:
              </h5>

              <ul className="listasteps">
                <li>
                  <strong className="text-secondary">Stage:</strong> es lo
                  primero que se hace, ejecutar las cargas de datos de las
                  fuentes externas, dichas ejecuciones se paralelizan
                </li>
                <li>
                  <strong className="text-secondary">Dim:</strong> despues de
                  que finalice el paso anterior, se ejecutan los procesos de
                  calculo de dimensiones
                </li>
                <li>
                  <strong className="text-secondary">Fact:</strong> después de
                  finalizar el paso anterior se ejectuan los procesos de calculo
                  de las tablas de hechos
                </li>
                <li>
                  <strong className="text-secondary">Olap:</strong> finalmente
                  se generan tablas desnormalizadas para publicar en los
                  distintos cloud para las visualización con Powerbi, Qllick.
                </li>
              </ul>

              <h5 className="font-weight-normal">
                <i className="las la-check text-secondary"></i> Definir reglas
                de control de calidad de datos para preservar el datos válido en
                todo momento
              </h5>
              <h5 className="font-weight-normal">
                <i className="las la-check text-secondary"></i> Versionar y
                testear procesos sin necesidad de tener que gestionar otro
                entorno duplicado en paralelo
              </h5>
              <h5 className="font-weight-normal">
                <i className="las la-check text-secondary"></i> Monitorizar en
                todo momento que se está ejecutando, evolución de tiempos de
                carga, de volumetría, reglas de calida fallidas, etc
              </h5>
              <h5 className="font-weight-normal">
                <i className="las la-check text-secondary"></i> Todo el equipo
                de BI tiene acceso a los procesos que son de su control para su
                evolución y gestión de incidencias, cualquier miembro es capaz
                de detectar de forma rápida los errores y meter cambios con un
                par de clicks
              </h5>

              <h4 className="text-secondary mt-4">
                ¿Cuantas personas han sido necesarias para el desarrollo inicial
                del DWH?
              </h4>
              <h5 className="text-uppercase">Un ingeniero de datos</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
